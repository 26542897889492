body {
    margin: 0;
    background-color: #F8F9FA !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.btn-primary {
    background-color: #76BC21 !important;
    border-color: #76BC21 !important;
}

.btn-outline-primary:hover {
    color: #FFF !important;
    background-color: #76BC21 !important;
}

.btn-outline-primary {
    color: #76BC21 !important;
    border-color: #76BC21 !important;
}

.navbar-link {
    color: #007bff !important;
    border-bottom: 1px solid #007bff!important
}

label {
    font-size: 18px !important;
}

video {
    width: 100%;
}

.sidebarStyle {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    margin: 12px;
    background-color: #404040;
    color: #ffffff;
    z-index: 1 !important;
    padding: 6px;
    font-weight: bold;
}

#map {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.marker {
    background-image: url('map-icon.png');
    background-size: cover;
    top: -10px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: #76BC21;
}

.mapboxgl-popup {
    max-width: 400px;
    font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.mapboxgl-popup-content {
    text-align: center;
}

.map-body {
    min-height: 400px !important;
}

.font-size-small {
    font-size: 14px !important;
}

.cursor-pointer:hover {
    cursor: pointer;
}

.max-height-200 {
    min-height: 200px;
    max-height: 200px;
}

.text-center {
    text-align: center;
}
